// Icons
@import "../public/css/bootstrap.min.css";
@import "../public/css/font-awesome.min.css";
@import "../public/css/material-design.css";
@import "~linearicons";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "../public/css/stylesheet.css";
@import "../public/css/homebuilder.css";
@import "../public/css/swiper.min.css";
@import "~kovarp-jquery-cookiebar/jquery.cookieBar";

@import "../public/fancybox/dist/jquery.fancybox.min.css";
@import "../public/css/math.css";
@import "../public/css/ihavecookies.css";
@import "../public/css/ribbons.css";

.slick-prev {
  left: 5px;
  z-index: 1;
}

.slick-next {
  right: 7px;
}

.slick-prev:before {
  color: beige!important;
}
.slick-next:before {
  color: beige!important;
}



